import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import ChatWidget from './ChatWidget.js';
import './App.css';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter

function App() {
  const [showChatWidget, setShowChatWidget] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChatWidget(true);
    }, 5000); 

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Box
          opacity={showChatWidget ? 1 : 0}
          transition="opacity 1s ease-in-out"
        >
          {showChatWidget && <ChatWidget />}
        </Box>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
