import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Img,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
  Button,
  Box,
  Image,
  Link,
} from '@chakra-ui/react';
import { IoMdSend } from 'react-icons/io';
import { BiReset } from 'react-icons/bi';
import chatLogo from './assets/images/sherplogo.png';
import chatIconNew from './assets/images/sherpchaticon.svg';
import client1 from './assets/images/client1.png';
import online from './assets/images/online.svg';
import {
  fetchContacts,
  GetGoHighLevelToken,
  SaveToBlob,
  sendMessage,
  SNSChatBotService,
} from './services/Service.js';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import MarkdownRenderer from './MarkdownRender.js';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IoMdClose } from 'react-icons/io';
import rehypeRaw from 'rehype-raw';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ChatWidget = () => {
  const query = useQuery();
  let clientName = query.get('client_name');
  let enrollmentStartDate = query.get('enrollment_start_date');
  let enrollmentEndDate = query.get('enrollment_end_date');
  // clientName = "cilia";
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem('chatHistory');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [newMessage, setNewMessage] = useState('');
  const [history, setHistory] = useState([]);
  const faqRef = useRef();
  const textareaRef = useRef();
  // const navigate = useNavigate();

  const [showClose, setShowClose] = useState(true);

  const saveChatHistory = updatedMessages => {
    setMessages(updatedMessages);
    sessionStorage.setItem('chatHistory', JSON.stringify(updatedMessages));
  };
  const [sessionId, setSessionId] = useState('');
  const generateSessionId = () => {
    return 'sess_' + Math.random().toString(36).substr(2, 9);
  };

  const [token, setToken] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    let lastmessage = messages[messages.length - 1];

    if (
      lastmessage?.loading === false &&
      lastmessage?.decision === 'redirect_to_HL' &&
      !hasFetched
    ) {
      console.log('lastmessage', lastmessage);
      fetchAndCreate();
      setHasFetched(true); // Set the guard to true after calling fetchAndCreat
    }
  }, [messages, hasFetched]);

  const fetchAndCreate = async () => {
    if (token) {
      try {
        const extractedData = messages[messages.length - 1]?.summary;
        let blobResponse = await SaveToBlob(JSON.stringify(extractedData));
        console.log('Blob response:', blobResponse);

        const fetchedContacts = await fetchContacts(locationId, token);

        if (fetchedContacts.statusCode === 401) {
          console.error('401 Unauthorized: Token might be invalid or expired.');

          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const currentMessageIndex = updatedMessages.length - 1;
            updatedMessages[currentMessageIndex] = {
              ...updatedMessages[currentMessageIndex],
              error:
                "We couldn't create your support request; please contact our support team for assistance.",
              loading: false,
            };
            return updatedMessages;
          });
        }

        console.log('Fetched contacts:', fetchedContacts);

        let contactId = '';
        let contactData = [];

        fetchedContacts.contacts?.forEach(contact => {
          if (contact.phone === phoneNumber.replace(/\s+/g, '')) {
            contactId = contact.id;
            contactData.push(contact);
            console.log('Match found, using contact ID:', contactId);
          }
        });

        if (!contactId) {
          console.log('No matching contact found');
        }

        if (contactId) {
          let messagePayload = {
            type: 'Email',
            contactId: contactId,
            attachments: [blobResponse?.url],
            message:
              "Hello! I'm Sherp, your Benefit Sherpa. How can I assist you today?\n\nIt's Open Enrollment time! This is your opportunity to review, update, or enroll in your insurance benefits for the upcoming year.\nDon't miss out—the Open Enrollment period ends on December 13th at 07:00 PM. Make sure to make any changes or enroll in your desired plans before the deadline.",
            subject: 'Conversation Summary',
            emailTo: contactData[0]?.email,
            emailReplyMode: 'reply',
          };

          const conversationResponse = await sendMessage(messagePayload, token);
          console.log('Conversation created:', conversationResponse);
        }
      } catch (error) {
        console.log('🚀 ~ fetchAndCreate ~ error:', error);
      }
    }
  };
  useEffect(() => {
    const id = generateSessionId();
    setSessionId(id);
    sessionStorage.setItem('sessionId', id);
  }, []);

  const fetchGoHighLevelToken = async clientName => {
    try {
      const response = await GetGoHighLevelToken(clientName);
      setToken(response?.access_token);
      setLocationId(response?.location_id);
      setPhoneNumber(response?.phone_number);
      console.log('response::>', response);
      localStorage.setItem(
        'accessToken',
        JSON.stringify(response?.access_token)
      );
    } catch (error) {
      console.error('Error fetching GoHighLevel token:', error);
      // toast({
      //   title: "Error fetching token",
      //   description: "There was an error fetching the GoHighLevel token. Please try again later.",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
    }
  };

  useEffect(() => {
    if (clientName) {
      fetchGoHighLevelToken(clientName);
    }
  }, [clientName]);

  function clearLocalStorage() {
    sessionStorage.clear();
    setMessages([]);
    setHistory([]);
    setNewMessage('');
    const id = generateSessionId();
    setSessionId(id);
    sessionStorage.setItem('sessionId', id);
  }

  useEffect(() => {
    saveChatHistory(messages);
    // setHistory(messages);
    ScrollToBottom();
  }, [messages]);

  const ScrollToBottom = () => {
    if (faqRef.current) {
      faqRef.current.scrollTop = faqRef.current.scrollHeight;
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    if (!isChatOpen && messages.length === 0) {
      sendInitialMessage();
    }
  };

  const sendInitialMessage = async () => {
    const errorMessage = 'Check your internet connection and try again.';

    setMessages(prevMessages => {
      const filteredMessages = prevMessages.filter(
        msg => msg.answer !== errorMessage
      );
      return [
        ...filteredMessages,
        {
          // query: message,
          answer: '',
          loading: true,
        },
      ];
    });
    const initialQuestion = 'Hello.';
    const payload = {
      message: initialQuestion,
      conversation_history: history,
      user_collected_info: {},
      session_id: sessionId,
      enrollment_start_date:
        enrollmentStartDate || new Date().toISOString().replace(/Z$/, ''),
      enrollment_end_date:
        enrollmentEndDate ||
        new Date(new Date().setDate(new Date().getDate() + 15))
          .toISOString()
          .replace(/Z$/, ''),
      current_date: new Date().toISOString().replace(/Z$/, ''),
      client_name: clientName || 'cilia',
      browser_language: 'en',
    };
    try {
      const response = await SNSChatBotService(payload);
      if (response && response.response) {
        // setMessages(prevMessages => [
        //   ...prevMessages,
        //   {
        //     query: '',
        //     answer: response.response,
        //     loading: false,
        //   },
        // ]);
        setHistory(response.conversation_history);
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const currentMessageIndex = updatedMessages.length - 1;
          updatedMessages[currentMessageIndex] = {
            ...updatedMessages[currentMessageIndex],
            answer: response.response,
            loading: false,
          };
          return updatedMessages;
        });
      }
    } catch (error) {
      console.error('Error:', error);
      // setLoading(false);
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const currentMessageIndex = updatedMessages.length - 1;
        updatedMessages[currentMessageIndex] = {
          ...updatedMessages[currentMessageIndex],
          answer: 'Check your internet connection and try again.',
          loading: false,
        };
        return updatedMessages;
      });
    }
  };

  const handleSubmit = async message => {
    if (message.trim() === '') {
      return;
    }

    const errorMessage = 'Check your internet connection and try again.';

    setMessages(prevMessages => {
      const filteredMessages = prevMessages.filter(
        msg => msg.answer !== errorMessage
      );
      return [
        ...filteredMessages,
        {
          query: message,
          answer: '',
          loading: true,
        },
      ];
    });

    setNewMessage('');
    textareaRef.current.style.height = '80px';
    try {
      setLoading(true);
      const url = window.location.href;
      const browserLanguage = url.includes('-es') ? 'es' : 'en';
      const payload = {
        message,
        conversation_history: history,
        user_collected_info: messages[messages.length - 1]?.user_info || {
          greeting_already_made: 'true',
        },
        session_id: sessionId,
        enrollment_start_date:
          enrollmentStartDate || new Date().toISOString().replace(/Z$/, ''),
        enrollment_end_date:
          enrollmentEndDate ||
          new Date(new Date().setDate(new Date().getDate() + 15))
            .toISOString()
            .replace(/Z$/, ''),
        current_date: new Date().toISOString().replace(/Z$/, ''),
        client_name: clientName || 'cilia',
        browser_language: browserLanguage,
      };
      const response = await SNSChatBotService(payload);
      if (response && response.response) {
        const answer = response;
        console.log('answer', answer);
        if (answer.decision === 'redirect_to_HL') {
          setHistory(response.conversation_history);
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const currentMessageIndex = updatedMessages.length - 1;
            updatedMessages[currentMessageIndex] = {
              ...updatedMessages[currentMessageIndex],
              answer: answer.response,
              decision: answer.decision,
              summary: answer.summary,
              loading: false,
              user_info: answer?.user_info,
            };
            return updatedMessages;
          });
        } else {
          setHistory(response.conversation_history);
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const currentMessageIndex = updatedMessages.length - 1;
            updatedMessages[currentMessageIndex] = {
              ...updatedMessages[currentMessageIndex],
              answer: answer.response,
              decision: answer.decision,
              summary: answer.summary,
              loading: false,
              user_info: answer?.user_info,
            };
            return updatedMessages;
          });
        }
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const currentMessageIndex = updatedMessages.length - 1;
        updatedMessages[currentMessageIndex] = {
          ...updatedMessages[currentMessageIndex],
          answer: 'Check your internet connection and try again.',
          loading: false,
        };
        return updatedMessages;
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setNewMessage(value);
    textareaRef.current.style.height = '40px';
    textareaRef.current.style.height = `${Math.min(
      textareaRef.current.scrollHeight,
      100
    )}px`;
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey && !loading) {
      event.preventDefault();
      handleSubmit(newMessage);
    }
  };

  return (
    <div>
      <div class="stack">
      {showClose && ( <div class="box" id="box">
          <button class="close-btn" id="closeBtn" onClick={() => setShowClose(!showClose)}>
            &#x2715;
          </button>
          <img
            class="profile-logo"
            src={client1}
            alt="Chat Logo"
            onClick={toggleChat}
          />
          <p class="chat-text" onClick={toggleChat}>
            Hello. I'm Sherp, your Benefits Sherpa. When you need help, I'm here!
          </p>
        </div> )}
      </div>


      <div
        onClick={() => {
          toggleChat();
          setShowClose(false);
        }}
      >
        {isChatOpen ? (
          <div className="chat-icon-close">
            <IoCloseSharp color="white" size={'20px'} />
          </div>
        ) : (
          <div className="chat-icon">
            <Img
              // bg="white"
              borderRadius="full"
              w={{ base: '60px' }}
              h={{ base: '100px' }}
              src={chatIconNew}
              objectFit="contain"
            />
          </div>
        )}
      </div>

      {isChatOpen && (
        <div className="chat-window">
          <Stack
            borderTopRadius={'20px'}
            px={6}
            py={2}
            direction="row"
            bg="#2a4f61"
            w="100%"
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Flex alignItems={'center'} gap={4}>
              <Img
                bg="white"
                borderRadius="full"
                w={{ base: '45px', md: '40px' }}
                h={{ base: '45px', md: '40px' }}
                src={chatLogo}
                objectFit="contain"
              />
              <Stack fontSize="sm" spacing={0}>
                <Text fontWeight={'500'} marginBottom={0} color="white">
                  SHERP
                </Text>
                <Flex alignItems={'center'} gap={2}>
                  <Img src={online} w="12px" h="12px" />
                  <Text color="white">online</Text>
                </Flex>
              </Stack>
            </Flex>
            <Stack direction={'row'} gap={5}>
              <Tooltip
                label="Clear Chat History"
                fontSize="12px"
                hasArrow
                marginRight="8px"
                placement="top"
                bg="gray.500"
                color="white"
              >
                <span>
                  <BiReset
                    onClick={clearLocalStorage}
                    color="white"
                    fontSize="25px"
                    cursor="pointer"
                  />
                </span>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack
            ref={faqRef}
            spacing={2}
            px={6}
            py={4}
            className="message-container"
            position="relative"
            overflowY="auto"
            overflowX="hidden"
            maxHeight="calc(100vh - 100px)"
            width="100%"
          >
            {messages?.map((message, index) => (
              <Stack key={index}>
                {message.query && (
                  <Text
                    whiteSpace="break-spaces"
                    className="bot-message"
                    width="fit-content"
                    fontSize={{ base: 'sm', md: 'md' }}
                    textAlign="left"
                    ml={1}
                  >
                    {message?.query}
                  </Text>
                )}
                <Stack pt={2} alignItems={'end'}>
                  {message?.loading ? (
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#2a4f61"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  ) : (
                    <Stack spacing={2} direction={'row'} alignItems="center">
                      <Img
                        bg="white"
                        borderRadius="full"
                        w={{ base: '45px', md: '55px' }}
                        h={{ base: '45px', md: '55px' }}
                        src={chatLogo}
                        objectFit="contain"
                        flexShrink={0}
                      />

                      <Text
                        whiteSpace="break-spaces"
                        className={
                          message?.answer ===
                            'Check your internet connection and try again.'
                            ? 'user-error-message'
                            : 'user-message'
                        }
                      >
                        <ReactMarkdown
                          children={message?.answer || ''}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            a: ({ href, children }) => (
                              <Link
                                href={href}
                                isExternal
                                fontStyle="italic"
                                fontWeight="600"
                                color="#2a4f61"
                              >
                                {children}
                              </Link>
                            ),
                          }}
                        />
                        <span style={{ fontWeight: 600, color: 'red' }}>
                          {message?.error}
                        </span>
                        {/*{index === messages.length - 1 && message?.decision !== 'redirect_to_HL' && localStorage.getItem('accessToken') && (
                          <Button onClick={handleButtonClick} height={7} mt={2}>Redirect to GHL</Button>
                        )}*/}
                      </Text>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Stack px={6} py={2} className="input-group" position="relative">
            <InputGroup bg="transparent">
              <InputRightElement
                onClick={() => handleSubmit(newMessage)}
                cursor="pointer"
                w={'5%'}
              >
                <IoMdSend color="#2a4f61" size="24px" />
              </InputRightElement>

              <Textarea
                variant="unstyled"
                placeholder="How can I help?"
                value={newMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                ref={textareaRef}
                resize={'none'}
                w={'90%'}
                style={{
                  height: '40px',
                  maxHeight: '80px',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontSize: '13px',
                }}
              />
            </InputGroup>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
