import config from '../config.json'; // Import the config file
// chat model API
export function SNSChatBotService(text) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify(
    text
  );

  return new Promise((resolve, reject) => {
    fetch(
      `https://sherpdev.aidevlab.com:8443/multitenants-chat`, // Fix the missing '=' here
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
}

// save survey info service
export function SaveSurveyInfoService(text) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);

  var raw = JSON.stringify(text);

  return new Promise((resolve, reject) => {
    fetch(
      `https://sherpdev.aidevlab.com:8443/save_survey_info`, // Fix the missing '=' here
      {
        signal: controller.signal,
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}


export function SaveToBlob(data) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);

  var raw = JSON.stringify({ data });

  return new Promise((resolve, reject) => {
    fetch(
      `https://backend-sherp-clonning.aidevlab.com/save_to_blob/`, // Fix the missing '=' here
      {
        signal: controller.signal,
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}

// High Level Endpoint
export function GetGoHighLevelToken(text) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');


  return new Promise((resolve, reject) => {
    fetch(
      `https://backend-sherp-clonning.aidevlab.com/get_token/${text}`, // Fix the missing '=' here
      {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }
    )
      .then(response => {
        // if (response.status === 401) {
        //   window.location = '/';
        //   localStorage.clear();
        // } else if (response.status === 503) {
        //   // Handle the 503 error gracefully without showing the error message
        //   // You can choose to resolve with a predefined value or null
        //   resolve(null); // Or resolve with a default response
        // } else if (response.status >= 500 && response.status < 550) {
        //   resolve('server error');
        // } else {
        resolve(response.json());
        // }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
}

// GET Contacts Service Function
export function fetchContacts(locationId, token) {
  var myHeaders = new Headers();
  if (token) {
    myHeaders.append('Authorization', `Bearer ${token}`); // Add Authorization header with Bearer token
  }
  myHeaders.append('Version', '2021-07-28');
  myHeaders.append('Accept', 'application/json');

  var url = `https://services.leadconnectorhq.com/contacts/`;
  var params = new URLSearchParams({ locationId }).toString();
  var fullUrl = `${url}?${params}`;

  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => {
        // if (!response.ok) {
        //   return reject(new Error(`HTTP error! status: ${response.status}`));
        // }
        return response.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log('Error:', error);
        reject(error);
      });
  });
}


// Create Contact Service Function
export function createContact(contactPayload, token) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  if (token) {
    myHeaders.append('Token', token);
  }
  myHeaders.append('Authorization', `Bearer ${token}`); // Replace with actual token
  myHeaders.append('Version', '2021-07-28');

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);

  var raw = JSON.stringify(contactPayload);

  return new Promise((resolve, reject) => {
    fetch(
      `https://services.leadconnectorhq.com/contacts/`, // Create Contact Endpoint
      {
        signal: controller.signal,
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then((response) => {
        // if (response.status === 401) {
        //   window.location = '/';
        //   localStorage.clear();
        // } else if (response.status === 503) {
        //   resolve(null); // Handle 503 error gracefully
        // } else if (response.status >= 500 && response.status < 550) {
        //   resolve('server error');
        // } else {
        resolve(response.json());
        // }
      })
      .catch((error) => {
        console.log('Error:', error);
        reject(error); // Reject promise if an error occurs
      });
  });
}

// Create Conversation Service Function
export function createConversation(conversationPayload, token) {
  var myHeaders = new Headers();
  if (token) {
    myHeaders.append('Token', token);
  }
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`); // Replace with actual token
  myHeaders.append('Version', '2021-04-15');


  var raw = JSON.stringify(conversationPayload);

  return new Promise((resolve, reject) => {
    fetch(
      'https://services.leadconnectorhq.com/conversations/', // Create Conversation Endpoint
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then((response) => {
        // if (response.status === 401) {
        //   window.location = '/';
        //   localStorage.clear();
        // } else if (response.status === 503) {
        //   resolve(null); // Handle 503 error gracefully
        // } else if (response.status >= 500 && response.status < 550) {
        //   resolve('server error');
        // } else {
        resolve(response.json());
        // }
      })
      .catch((error) => {
        console.log('Error:', error);
        reject(error); // Reject promise if an error occurs
      });
  });
}

// Create Message Service Function
export async function sendMessage(payload, token) {
  const url = 'https://services.leadconnectorhq.com/conversations/messages';

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Version: '2021-04-15',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      type: "Email",
      contactId: payload.contactId, // Dynamically set the contactId from payload
      attachments: payload.attachments || [], // Use attachments if provided
      message: payload.message, // The actual message (chat history or other)
      subject: payload.subject, // Email subject
      emailTo: payload.emailTo, // Recipient's email address
      emailReplyMode: payload.emailReplyMode || "reply", // Reply mode, default to "reply" if not provided
    }),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      return data; // Return the response data if successful
    } else {
      throw new Error('Error sending email: ' + (data.message || 'Unknown error')); // Throw an error if response is not OK
    }
  } catch (error) {
    console.error('Error:', error);
    throw error; // Throw the error to be caught in the calling function
  }
}

// {
//   "baseUrl": "https://marketplace.gohighlevel.com",
//   "clientId": "671b689ac7836a57cdca9f4b-m34obi27",
//   "clientSecret": "7ed33180-e57a-4d99-a382-409ab8925b17",
//   "redirectUri": "http://localhost:3000/oauth/callback",
//   "sos": "24216657-e744-404a-acb2-b5060be711ce",
//   "authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6InNMU1lkaDdUcGJSYlgzR1RoRzZLIiwidmVyc2lvbiI6MSwiaWF0IjoxNzI3MjExMjY3ODA3LCJzdWIiOiJ4aUFNZlJyRjhnVFVPZmlrRVJkWSJ9.7opF7zDF1tobH1eycAH8hP6OnsV3EoFJ7G4vhaxRzsQ"
// }